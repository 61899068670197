import FormattedTextField from '@/components/formattedTextField';
import Loading from '@/components/loading';
import Tips from '@/pages/dashboard/commerce/payment/tip';
import { useFetchCloverTips } from '@/pages/settings/cards';
import { GatewayBase } from '@/types/schema';
import { Card, CardActionArea, CardContent, Collapse, Grid, InputAdornment, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

export default function TipsSection( {
	cloverGateway,
	paying,
	showTips = true,
	showTipOptions = true,
	setShowTipOptions,
	tipPercent,
	setTipPercent,
	dollarTip,
	setDollarTip,
	selectedTipOption,
	setSelectedTipOption,
}: {
	cloverGateway: GatewayBase | null,
	paying: number,
	showTips?: boolean,
	showTipOptions?: boolean,
	setShowTipOptions: ( value: boolean ) => void,
	tipPercent: number,
	setTipPercent: ( value: number ) => void,
	dollarTip: number,
	setDollarTip: ( value: number ) => void,
	selectedTipOption: number,
	setSelectedTipOption: ( value: number ) => void
} ) {
	const { t } = useTranslation();
	const [ loading, cloverTips ] = useFetchCloverTips( cloverGateway?.id || '' );
	
	return (
		<Collapse in={showTips}>
			<Collapse in={showTipOptions} sx={{ width: '100%', mb: 1 }}>
				<Grid container spacing={1}>
					{cloverGateway?.id && loading
						? <Loading/>
						: !isEmpty( cloverTips ) ? (
							<Tips
								type='Clover'
								cloverTips={cloverTips}
								selectedTipOption={selectedTipOption}
								setSelectedTipOption={setSelectedTipOption}
								setTip={setTipPercent}
								setDollarTip={setDollarTip}
								paying={paying}
							/>
						) : (
							<Tips
								type='Invoiss'
								selectedTipOption={selectedTipOption}
								setSelectedTipOption={setSelectedTipOption}
								setTip={setTipPercent}
								setDollarTip={setDollarTip}
								paying={paying}
							/>
						)}
				</Grid>
			</Collapse>
			<Collapse in={!showTipOptions} sx={{ width: '100%', mb: 1 }}>
				<Grid container item spacing={1}>
					<Grid item xs={6}>
						<FormattedTextField
							fullWidth
							type='number'
							size='medium'
							InputProps={{ startAdornment: <InputAdornment position='start'>%</InputAdornment> }}
							value={tipPercent}
							sx={{ '.MuiInputLabel-root': { fontSize: '1.5rem !important' } }}
							onChange={( e ) => {
								setTipPercent( +e.target.value );
								setDollarTip( 0 );
							}}
							onFocus={( e ) => e.target.select()}
						/>
					</Grid>
					<Grid item xs={6}>
						<FormattedTextField
							fullWidth
							type='number'
							size='medium'
							InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
							value={dollarTip}
							sx={{ '.MuiInputLabel-root': { fontSize: '1.5rem !important' } }}
							onChange={( e ) => {
								setDollarTip( +e.target.value );
								setTipPercent( 0 );
							}}
							onFocus={( e ) => e.target.select()}
						/>
					</Grid>
				</Grid>
			</Collapse>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Card>
						<CardActionArea
							onClick={() => {
								setTipPercent( 0 );
								setDollarTip( 0 );
								setShowTipOptions( !showTipOptions );
								setSelectedTipOption( -1 );
							}}>
							<CardContent>
								<Typography variant='h5' textAlign='center'>
									{showTipOptions ? t( 'common:custom-tip' ) : t( 'common:tip-option' )}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card
						sx={selectedTipOption === 4 ? {
							border     : 2,
							borderColor: 'primary.main',
							color      : 'primary.main',
						} : {
							border     : 2,
							borderColor: 'divider',
						}}>
						<CardActionArea
							onClick={() => {
								setTipPercent( 0 );
								setDollarTip( 0 );
								setSelectedTipOption( 4 );
							}}>
							<CardContent>
								<Typography variant='h5' textAlign='center'>
									{t( 'common:no-tip' )}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
		</Collapse>
	);
}
