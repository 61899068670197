import { mutateGraphQL } from '@/data/apollo';
import { CommercesWrite } from '@/data/commerce/commerce.graphql';
import { LogWrite } from '@/data/log.graphql';
import { EmailValuesType, getCommerceEmailValues } from '@/pages/dashboard/commerce/invoices/syncAndPay';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { MutationCommercesWriteArgs, type MutationLogWriteArgs, Order } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { useSnackbar } from 'notistack';

export function EmailAllModal( {
	selectedInvoices,
	clearRows,
}: {
	selectedInvoices: Order[],
	clearRows: () => void
} ) {
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const timezone = getBrowserTimezone();
	const type = selectedInvoices[ 0 ].type === 'ORDER' ? 'order' : 'invoice';
	const invoicesWithClientEmail = selectedInvoices.filter( ( invoice ) => invoice.client?.email );
	
	return (
		<ResponsiveModalContainer
			saveButtonText='Send Email'
			title={`Email all ${selectedInvoices.length > 1 ? `${type}s` : type}`}
			saveButtonProps={{ disabled: isEmpty( invoicesWithClientEmail ) }}
			onSave={async () => {
				enqueueSnackbar( 'Please wait while the emails are being sent...', { variant: 'info' } );
				let emailCount = 0;
				let successEmailCount = 0;
				const invoicesMap: Record<string, boolean> = {};
				for ( const invoice of invoicesWithClientEmail ) {
					emailCount++;
					try {
						const emailValues: EmailValuesType = getCommerceEmailValues( staff, invoice );
						
						await axios.post( `/api/emails/${invoice.id}/invoiceOrderEstimate`, {
							...emailValues,
							timezone,
							// url    : data?.url,
							sendVia: 'ses',
						} );
						successEmailCount++;
						invoicesMap[ invoice.id ] = true;
					} catch {
					}
				}
				
				// set sent to true to only invoices whose id is in invoicesMap
				await mutateGraphQL<MutationCommercesWriteArgs>( {
					mutation : CommercesWrite,
					variables: {
						method: 'Emailed All',
						inputs: selectedInvoices.filter( ( invoice ) => invoicesMap[ invoice.id ] ).map( ( invoice ) => ( {
							id      : invoice.id,
							sent    : true,
							metadata: {
								...invoice.metadata,
								sentDates: [ ...!isEmpty( invoice.metadata?.sentDates )
									? [ ...invoice.metadata.sentDates, new Date() ]
									: [ new Date() ] ],
							},
						} ) ),
					},
				} );
				clearRows();
				enqueueSnackbar( successEmailCount > 0
					? 'Emails have been sent successfully'
					: emailCount > 0 && !successEmailCount ? 'Emails could not be sent.'
						: !emailCount
							? `No email addresses were found for the selected ${type}s.` : '', {
					variant: successEmailCount > 0
						? 'success'
						: 'error',
				} );
			}}>
			<Stack>
				<Stack spacing={1} justifyContent='center'>
					<Typography>{`An ${type} will be sent separately to each email address.`}</Typography>
					{isEmpty( invoicesWithClientEmail )
						? <Typography color='warning.main'>Please select {type}s with the client emails.</Typography>
						: invoicesWithClientEmail.map( ( invoice ) => (
							<Stack key={invoice.id} direction='row' alignItems='center' spacing={1}>
								<Typography variant='h6'>#{invoice.metadata?.customNumber || invoice.externalId || invoice.number}</Typography>
								<Typography>{invoice.client.email}</Typography>
							</Stack>
						) )}
				</Stack>
			</Stack>
		</ResponsiveModalContainer>
	);
}

export const extractExternalId = ( input: string ) => {
	if ( !input ) return '';
	const match = input.match( /^#([A-Z0-9]+)/ ); // Match the pattern starting with # followed by alphanumeric characters
	return match ? match[ 1 ] : '';
};

export const isStringANumber = ( value: string ) => !isNaN( parseFloat( value ) ) && isFinite( value );

export default function useWriteLog() {
	const { user } = useUserInfo();
	return async ( input: MutationLogWriteArgs[ 'input' ] ) => {
		if ( !input?.user ) input.user = user?.id;
		
		await mutateGraphQL<MutationLogWriteArgs>( {
			mutation : LogWrite,
			variables: { input },
		} );
	};
	
}
